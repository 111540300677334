<template>
  <bool-to-icon
    v-if="value != null && value != 'Err'"
    :value="value"
    :true-value-icon="'i.Checked'"
    true-value-color="success"
    :false-value-icon="'i.Close'"
    false-value-color="error"
  />
  <v-icon
    color="error"
    v-else-if="value === 'Err'"
  >{{$icon('i.Error')}}</v-icon>
</template>

<script>
export default {
  components: { BoolToIcon: () => import('@/components/bool-to-icon') },
  props: {
    value: [Boolean, String]
  }
}
</script>
